module services {
    export module master {
        export class sundryItemService implements interfaces.master.ISundryItemService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }
            
            getDropdownList(ownerEntityId: number,isInbound:boolean, searchText?: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "SundryItem/GetDropdownList", {
                    ownerEntityId: ownerEntityId,
                    isInbound: isInbound,
                    searchText: searchText
                });
            }

            getDropdownListForCostModel(ownerEntityId: number, costModelId: number, searchText?: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "SundryItem/GetDropDownListForCostModel", {
                    ownerEntityId: ownerEntityId,
                    costModelId: (costModelId === null) ? 0 : costModelId,
                    searchText: searchText
                });
            }

            getDefault(): ng.resource.IResourceClass<interfaces.master.ISundryItemDefault> {

                return this.$resource<interfaces.master.ISundryItemDefault>(this.ENV.DSP_URL + "SundryItem/GetDefault", {
                    sunId: '@sundId',
                    countryId: '@countryId',
                    checkDate: '@checkDate'
                });
            }
        }
    }
    angular.module("app").service("sundryItemService", services.master.sundryItemService);
}